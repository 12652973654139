import clsx from 'clsx'
import { t } from 'i18next'

// type Props = {
//     white?: boolean
// }

const SiteFooter = () => (
    <footer
        className={clsx('font-body', 'w-full', 'bg-gray-700', 'text-white', 'text-xs', 'py-6', 'px-4', 'sticky', 'top-[100vh]')}>
        <ul className={clsx('flex', 'justify-center', 'flex-wrap', 'space-x-2', 'leading-loose')}>
            <a
                href="https://www.ielove-group.jp/privacypolicy/"
                target="_blank"
                rel="noopener noreferrer"
                className={clsx('underline')}>
                {t('SiteFooter.個人情報保護方針')}
            </a>
            <a href="https://ielove-cloud.jp/company/" target="_blank" rel="noopener noreferrer" className={clsx('underline')}>
                {t('SiteFooter.運営会社')}
            </a>
        </ul>
        <div className={clsx('text-center', 'mt-6', 'tracking-widest')}>
            COPYRIGHT &copy; All rights reserved. Produced by IeloveGroup
        </div>
    </footer>
)

export default SiteFooter
