import clsx from 'clsx'
import { ReactNode, useEffect, useState, VFC } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { useClientAuthClientMeQuery } from '~/app/api'
import apiBase from '~/app/apiBase'
import InitialDialog from '~/components/global/gDialog/InitialDialog'
import { selectCredential, setCredentials } from '~/util/store/authSlice'
import { useAppDispatch, useAppSelector } from '~/util/store/hooks'
import { selectLoginUser, setLoginUser } from '~/util/store/userSlice'

export type RouteClientAuthGuardProps = {
    children?: ReactNode
}

export const RouteClientAuthGuard: VFC<RouteClientAuthGuardProps> = ({ children }: RouteClientAuthGuardProps) => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const user = useAppSelector(selectLoginUser)
    const cred = useAppSelector(selectCredential)

    const { data: teamMember, isLoading, isError, error } = useClientAuthClientMeQuery(undefined, { skip: !!user && !!cred })

    // 初回ダイアログ表示の状態管理
    const [showDialog, setShowDialog] = useState(false)

    useEffect(() => {
        if (!isLoading && teamMember) {
            dispatch(setLoginUser(teamMember))
            localStorage.removeItem('initialDialogShown') // ログイン時にフラグをリセット
        }
    }, [isLoading, teamMember, dispatch])

    useEffect(() => {
        if (isError) {
            dispatch(apiBase.util.resetApiState()) // RTK Queryのキャッシュクリア
            if (user || cred) {
                dispatch(setCredentials({ credential: undefined }))
                dispatch(setLoginUser(undefined))
            }
            navigate('/user/ielove', { state: { from: location } })
        }
    }, [isError, error, dispatch, navigate, location, user, cred])

    // ログイン後の初回アクセスでダイアログを表示する処理
    useEffect(() => {
        if (user && cred && !localStorage.getItem('initialDialogShown')) {
            setShowDialog(true)
            localStorage.setItem('initialDialogShown', 'true')
        }
    }, [user, cred])

    const handleDialogClose = () => {
        setShowDialog(false)
    }

    // 認証済みの場合の表示
    if (user && cred) {
        return (
            <>
                {showDialog && (
                    <InitialDialog onClose={handleDialogClose}>
                        <p>この度キマールサービスは、いえらぶCLOUDへ移行されました。</p>
                        <p>
                            ご案内したID及びパスワードをご利用いただき、
                            <Link className={clsx('underline', 'storog')} to="https://cloud.ielove.jp/">
                                <>いえらぶCLOUD</>
                            </Link>
                            へログインしてください。
                        </p>
                        <p>3月1日以降に旧キマールサービスで編集・登録した情報は移行後には反映されませんのでご注意ください。</p>
                        <p>なお、旧キマールサービスは3月31日をもってサービス終了させていただきます。</p>
                    </InitialDialog>
                )}
                {children}
            </>
        )
    }

    if (isLoading) return <></>
    return <>{children}</>
}
