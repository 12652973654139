import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast/headless'
import { useNavigate } from 'react-router-dom'

import { InquiryInsertDto, useClientInquiryPostInquiryMutation } from '~/app/api'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { ApplicationError, IApplicationError } from '~/types/error'
import { pageTitleTemplate } from '~/util/common/common'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser } from '~/util/store/userSlice'

import { CButton } from '../../components/common/cButton/CButton'
import { CCheckBoxList } from '../../components/common/cCheckBoxList/CCheckBoxList'
import { CError } from '../../components/common/cError/CError'
import { CLabeledItem } from '../../components/common/cLabeledItem/CLabeledItem'
import { CTextInput } from '../../components/common/cTextInput/CTextInput'

const Inquiry = () => {
    const navigate = useNavigate()
    const user = useAppSelector(selectLoginUser)
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const [inquiryAPI, { isSuccess: isInquiryAPISuccess }] = useClientInquiryPostInquiryMutation()
    const [form, setForm] = useState<InquiryInsertDto>({
        email: user ? user.email : '',
        companyName: '',
        name: '',
        phone: '',
        subject: [],
        content: '',
    })
    const subjects = [
        { label: t('Inquiry.キマールの詳細な資料が欲しい'), value: 'キマールの詳細な資料が欲しい' },
        { label: t('Inquiry.キマールについて詳しく知りたい'), value: 'キマールについて詳しく知りたい' },
        { label: t('Inquiry.キマールを試してみたい'), value: 'キマールを試してみたい' },
        { label: t('Inquiry.その他'), value: 'その他' },
    ]

    const formChangeHandler = (value: string | unknown, target: keyof typeof form) => {
        setForm((oldValue) => ({ ...oldValue, [target]: value }))
    }

    // お問い合わせフォームバリデーション
    const validate = () => {
        const errorList: Array<ApplicationError> = []
        if (form.companyName.trim().length === 0) errorList.push(new ApplicationError(t('Inquiry.会社名が入力されていません')))
        if (form.name.trim().length === 0) errorList.push(new ApplicationError(t('Inquiry.お名前が入力されていません')))
        if (form.email.trim().length === 0) errorList.push(new ApplicationError(t('Inquiry.メールアドレスが入力されていません')))
        if (form.phone.trim().length === 0) errorList.push(new ApplicationError(t('Inquiry.電話番号が入力されていません')))
        if (form.subject.length === 0) errorList.push(new ApplicationError(t('Inquiry.お問い合わせ内容が選択されていません')))

        if (errorList.length > 0) {
            setErrors(errorList)
            return false
        }
        return true
    }

    const onSubmit = async () => {
        setErrors([])
        if (!validate()) return
        try {
            await inquiryAPI({ inquiryInsertDto: form }).unwrap()
            toast.success(t('Inquiry.お問い合わせを送信しました'))
            setTimeout(() => {
                if (!user) navigate('/')
                else navigate('/dashboard')
            }, 3000)
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            else setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    return (
        <div className={clsx('text-gray-700', 'font-body', 'w-full', 'flex', 'flex-col', 'items-center', 'gap-4', 'p-6')}>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('Inquiry.お問い合わせ')}</title>
            </Helmet>
            <CHeader label={t('Inquiry.お問い合わせ')} />
            <div className={clsx('flex', 'flex-col', 'items-center', 'gap-4', 'mb-6')}>
                <CHeader label={t('Inquiry.お電話でのお問い合わせ')} bgClass="bg-kimar-accent" />
                <CButton
                    onClick={onSubmit}
                    className={clsx(
                        'rounded-md',
                        'bg-kimar-primary',
                        'text-white',
                        'text-2xl',
                        'tracking-widest',
                        'hover:bg-white',
                        'hover:text-kimar-primary',
                        'border',
                        'border-kimar-primary',
                    )}>
                    <a
                        className={clsx('flex', 'flex-row', 'flex-wrap', 'justify-center', 'gap-x-4', 'gap-y-1', 'p-4')}
                        href="tel:03-5324-2810">
                        <div className={clsx('flex', 'flex-row', 'items-end', 'gap-1', 'font-bold')}>
                            <span className={clsx('text-sm')}>{t('Inquiry.TEL')}</span>
                            <span className={clsx('text-3xl')}>03-5324-2810</span>
                        </div>
                        <div className={clsx('flex', 'flex-col', 'items-center', 'text-sm')}>
                            <span>{t('Inquiry.営業時間/平日')}</span>
                            <span>{t('Inquiry.10：00 〜 18：00')}</span>
                        </div>
                    </a>
                </CButton>
            </div>
            <div className={clsx('flex', 'flex-col', 'items-center', 'gap-4', 'w-full')}>
                <CHeader label="WEBからのお問い合わせ" bgClass="bg-kimar-accent" />
                <div className={clsx('bg-white', 'rounded-md', 'px-10', 'py-5', 'mb-10', 'w-full', 'max-w-[768px]')}>
                    <CError errors={errors} />
                    {isInquiryAPISuccess ? (
                        // NOTE: 回答時のid名・文言を使ってgoogle広告のコンバージョンを計測しているため変更時は要注意
                        <CMessage id="inquiry-success" success centered>
                            {t('Inquiry.お問い合わせを送信しました。')}
                        </CMessage>
                    ) : (
                        <div>
                            <div className={clsx('w-full', 'my-4')}>
                                <CLabeledItem required label={t('Inquiry.会社名')}>
                                    <CTextInput
                                        className={clsx('w-full', 'c-text-input-base')}
                                        onChange={(value) => formChangeHandler(value, 'companyName')}
                                        type={'text'}
                                        placeholder={t('Inquiry.株式会社いえらぶGROUP')}
                                    />
                                </CLabeledItem>
                            </div>
                            <div className={clsx('w-full', 'my-4')}>
                                <CLabeledItem required label={t('Inquiry.お名前')}>
                                    <CTextInput
                                        className={clsx('w-full', 'c-text-input-base')}
                                        onChange={(value) => formChangeHandler(value, 'name')}
                                        type={'text'}
                                        placeholder={t('Inquiry.キマール 太郎')}
                                    />
                                </CLabeledItem>
                            </div>
                            <div className={clsx('w-full', 'my-4')}>
                                <CLabeledItem required label={t('Inquiry.メールアドレス')}>
                                    <CTextInput
                                        className={clsx('w-full', 'c-text-input-base')}
                                        onChange={(value) => formChangeHandler(value, 'email')}
                                        type={'text'}
                                        placeholder="mail@example.com"
                                        text={form.email}
                                    />
                                </CLabeledItem>
                            </div>
                            <div className={clsx('w-full', 'my-4')}>
                                <CLabeledItem required label={t('Inquiry.電話番号')}>
                                    <CTextInput
                                        className={clsx('w-full', 'c-text-input-base')}
                                        onChange={(value) => formChangeHandler(value, 'phone')}
                                        type={'text'}
                                        placeholder="090-0000-0000"
                                    />
                                </CLabeledItem>
                            </div>
                            <div className={clsx('w-full', 'my-4')}>
                                <CLabeledItem required label={t('Inquiry.お問い合わせ内容（複数選択可）')}>
                                    <CCheckBoxList
                                        checked={form.subject}
                                        onChange={(value) => formChangeHandler(value, 'subject')}
                                        items={subjects}
                                    />
                                </CLabeledItem>
                            </div>
                            <div className={clsx('w-full', 'my-4')}>
                                <CLabeledItem label={t('Inquiry.自由欄')}>
                                    <CTextInput
                                        className={clsx('w-full', 'c-text-input-base')}
                                        onChange={(value) => formChangeHandler(value, 'content')}
                                        type="multiline"
                                        placeholder={t('Inquiry.お問い合わせの詳細を記載してください')}
                                    />
                                </CLabeledItem>
                            </div>
                            <div>
                                <CButton onClick={onSubmit} className={clsx('w-full', 'c-button-primary')}>
                                    {t('Inquiry.お問い合わせを送信する')}
                                </CButton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Inquiry
