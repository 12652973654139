import { useEffect } from 'react'

// hbsptの未定義エラーを回避 ローカル型定義を作成
interface MyWindow extends Window {
    hbspt?: {
        forms: {
            create: (options: { portalId: string; formId: string; target: string }) => void
        }
    }
}

const CDownloadForm = () => {
    useEffect(() => {
        const script = document.createElement('script')
        script.src = '//js.hsforms.net/forms/embed/v2.js'
        script.type = 'text/javascript'
        script.charset = 'utf-8'
        script.onload = () => {
            // window をローカル型にキャストして利用する
            const w = window as MyWindow
            if (w.hbspt) {
                w.hbspt.forms.create({
                    portalId: '7279184',
                    formId: '6413ab02-4341-4d2a-8fa0-0455b51ad251',
                    target: '#hubspotForm',
                })
            }
        }
        document.body.appendChild(script)
    }, [])

    return <div id="hubspotForm" className="w-4/5 mx-auto rounded-xl p-9 bg-[#DFF5F4]" />
}

export default CDownloadForm
