import React, { VFC } from 'react'

export type InitialDialogProps = {
    onClose: () => void
    children: React.ReactNode
}

const InitialDialog: VFC<InitialDialogProps> = ({ onClose, children }) => {
    return (
        <div className="dialog-overlay" style={overlayStyle}>
            <div className="dialog-content" style={dialogStyle}>
                <div className="dialog-body mb-8">{children}</div>
                <div className="dialog-actions" style={actionsStyle}>
                    <button onClick={onClose} style={buttonStyle}>
                        閉じる
                    </button>
                </div>
            </div>
        </div>
    )
}

// 今度いじらないため直書きで対応
const overlayStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
}

const dialogStyle: React.CSSProperties = {
    background: '#fff',
    borderRadius: '8px',
    padding: '20px',
    maxWidth: '500px',
    width: '100%',
}

const actionsStyle: React.CSSProperties = {
    textAlign: 'right',
}

const buttonStyle: React.CSSProperties = {
    padding: '8px 16px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
}

export default InitialDialog
