import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { slide as Menu } from 'react-burger-menu'
import { useMediaQuery } from 'react-responsive'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import {
    ClientRealEstateActivityBooleanDto,
    CompanyEntities,
    TeamMemberEntities,
    useClientRealEstateMarketHasNewMarketEntityQuery,
    useClientTeamMemberHasNoReadTeamActivityQuery,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CompanyLogo } from '~/components/unique/company/CompanyLogo'
import { selectLoginUser, selectLoginUserCompany, selectLoginUserHaveMarketContract } from '~/util/store/userSlice'

import { useAppSelector } from '../../../util/store/hooks'

export const activeButtonClass = ['bg-kimar-primary', 'text-white', 'py-1', 'px-2', 'font-bold', 'rounded']
export const passiveButtonClass = ['hover:bg-kimar-primary-light', 'py-1', 'px-2', 'rounded']

const SiteHeader = () => {
    const company: CompanyEntities | undefined = useAppSelector(selectLoginUserCompany)
    const user: TeamMemberEntities | undefined = useAppSelector(selectLoginUser)
    const haveMarketContract = useAppSelector(selectLoginUserHaveMarketContract)
    const location = useLocation()
    const navigate = useNavigate()
    const isMobile = useMediaQuery({ maxWidth: '768px' })
    const [isPublicPath, setIsPublicPath] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [homeUrl, setHomeUrl] = useState('/')

    const publicPath = [
        /^\/$/,
        /^\/#.*$/,
        /^\/user\/reminder$/,
        /^\/user\/signin$/,
        /^\/user\/ielove$/,
        /^\/user\/reset\/.*$/,
        /^\/user\/activate\/.*$/,
        /^\/download$/,
        /^\/inquiry$/,
        /^\/faq$/,
        /^\/eura$/,
        /^\/privacy$/,
        /^\/scta_notation$/,
        /^\/purchase_condition\/.*\/edit$/,
    ]

    const adminPath = [/^\/admin\/.*$/]

    useEffect(() => {
        const _isAdmin = !!adminPath.find((pattern) => location.pathname.match(pattern))
        setIsAdmin(_isAdmin)
        const _isPublicPath = !!publicPath.find((pattern) => location.pathname.match(pattern))
        setIsPublicPath(_isPublicPath)
        const _homeUrl = _isAdmin ? '/admin/dashboard' : _isPublicPath ? '/' : '/dashboard'
        setHomeUrl(_homeUrl)
    }, [location.pathname, user])

    const signOut = async () => {
        navigate('/signout')
    }

    const onClickSignOut = () => {
        setOpenMenu(false)
        setSignoutVisible(true)
    }

    const [openMenu, setOpenMenu] = useState(false)
    const [signoutVisible, setSignoutVisible] = useState(false)
    const { data: hasNewMarketEntity } = useClientRealEstateMarketHasNewMarketEntityQuery(void {}, { skip: !user })
    const { data: activity } = useClientTeamMemberHasNoReadTeamActivityQuery(void {}, { skip: !user })

    return (
        <>
            {isMobile ? (
                <>
                    <header
                        className={clsx(
                            'sticky',
                            'top-0',
                            'w-full',
                            'h-20',
                            'px-3',
                            'border-b',
                            'border-gray-300',
                            'bg-white',
                            'text-sm',
                            'z-50',
                            'flex',
                            'flex-col',
                            'items-center',
                            'space-y-1',
                            'whitespace-nowrap',
                        )}>
                        <div className={clsx('w-full', 'flex', 'justify-between', 'space-x-2', 'p-1')}>
                            <Link to={homeUrl} className={clsx('flex-grow', 'flex', 'justify-center')}>
                                <CompanyLogo />
                            </Link>
                            {/* ハンバーガーメニュー */}
                            <CButton onClick={() => setOpenMenu(!openMenu)}>
                                {openMenu ? (
                                    <i className={clsx('material-icons-outlined', 'text-2xl', 'text-kimar-primary')}>close</i>
                                ) : (
                                    <i className={clsx('material-icons-outlined', 'text-2xl', 'text-kimar-primary')}>menu</i>
                                )}
                            </CButton>
                        </div>
                        {isAdmin ? <AdminTopMenu /> : !isPublicPath || user ? <UserTopMenu /> : <GuestTopMenu />}
                    </header>
                    <Menu right customBurgerIcon={false} isOpen={openMenu} width={280} className={clsx('p-4', 'bg-white')}>
                        {isAdmin ? (
                            <AdminMenu company={company} user={user} onClickSignOut={() => onClickSignOut()} />
                        ) : !isPublicPath || user ? (
                            <UserMenu company={company} user={user} onClickSignOut={() => onClickSignOut()} />
                        ) : (
                            <GuestMenu />
                        )}
                    </Menu>
                </>
            ) : (
                <header
                    className={clsx(
                        'sticky',
                        'top-0',
                        'w-full',
                        'h-16',
                        'px-3',
                        'border-b',
                        'border-gray-300',
                        'bg-white',
                        'text-sm',
                        'z-50',
                        'flex',
                        'items-center',
                        'space-x-2',
                        'whitespace-nowrap',
                    )}>
                    <Link to={homeUrl}>
                        <CompanyLogo />
                    </Link>
                    {isAdmin ? (
                        <PcAdminTopMenu company={company} user={user} onClickSignOut={() => onClickSignOut()} />
                    ) : !isPublicPath || user ? (
                        <PcUserTopMenu
                            activity={activity}
                            hasNewMarketEntity={haveMarketContract && !!hasNewMarketEntity?.length}
                            company={company}
                            user={user}
                            onClickSignOut={() => onClickSignOut()}
                        />
                    ) : (
                        <PcGuestTopMenu />
                    )}
                </header>
            )}
            {/* ログアウト確認モーダル */}
            <CConfirmModal
                visible={!!signoutVisible}
                onRequestClose={() => setSignoutVisible(false)}
                confirmLabel={t('SiteHeader.ログアウト')}
                onRequestConfirm={() => signOut()}>
                {t('SiteHeader.ログアウトします。よろしいですか？')}
            </CConfirmModal>
        </>
    )
}

export type UserMenuProps = {
    company?: CompanyEntities
    user?: TeamMemberEntities
    onClickSignOut: () => void
}

const UserMenu = (props: UserMenuProps) => {
    const { company, user, onClickSignOut } = props
    return (
        <>
            <div className={clsx('flex', 'flex-col', 'justify-between', 'h-[80vh]')}>
                <div className={clsx('flex', 'space-y-4', 'flex-col')}>
                    <CLinkButton to={'/faq'} className={clsx('p-2')}>
                        {t('SiteHeader.よくあるご質問')}
                    </CLinkButton>
                    <div className={clsx('p-2')}>
                        <a href="https://teachme.jp/92337/f/kimar_manual?page=1" target="_blank" rel="noopener noreferrer">
                            {t('SiteHeader.使い方')}
                        </a>
                    </div>
                    <CLinkButton to={'/inquiry'} className={clsx('p-2')}>
                        {t('SiteHeader.お問い合わせ')}
                    </CLinkButton>
                </div>
                <div className={clsx('flex', 'space-y-4', 'flex-col')}>
                    <div className={clsx('text-sm')}>
                        <div>{company?.name}</div>
                        <div>{user?.name}</div>
                    </div>
                    <div className={clsx('h-px', 'w-5/6', 'bg-gray-300')} />
                    <CLinkButton to={'/user/mypage'} className={clsx('flex', 'p-2', 'space-x-2')}>
                        <img src="/src/assets/image/icon/mypage.svg" alt="faq" />
                        <span>マイページ</span>
                    </CLinkButton>
                    {user?.roles.system && (
                        <CLinkButton className={clsx('c-button-secondary')} to={'/admin/dashboard'}>
                            <img src="/src/assets/image/admin_logo.svg" alt="logo" width="60" />
                            を開く
                        </CLinkButton>
                    )}
                    <CButton className={clsx('p-2', 'flex', 'space-x-2')} onClick={() => onClickSignOut()}>
                        <img src="/src/assets/image/icon/signout.svg" alt="signOut" />
                        <span>ログアウト</span>
                    </CButton>
                </div>
            </div>
        </>
    )
}

export type AdminMenuProps = {
    company?: CompanyEntities
    user?: TeamMemberEntities
    onClickSignOut: () => void
}

const AdminMenu = (props: AdminMenuProps) => {
    const { company, user, onClickSignOut } = props
    return (
        <>
            <div className={clsx('flex', 'flex-col', 'justify-between', 'h-[80vh]')}>
                <div className={clsx('flex', 'space-y-4', 'flex-col')}>
                    <CLinkButton
                        className={
                            location.pathname.match('/admin/dashboard') ? clsx(activeButtonClass) : clsx(passiveButtonClass)
                        }
                        to={'/admin/dashboard'}>
                        {t('SiteHeader.KPI')}
                    </CLinkButton>
                    <CLinkButton
                        className={location.pathname.match('/admin/company') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                        to={'/admin/company'}>
                        {t('SiteHeader.会社')}
                    </CLinkButton>
                    <CLinkButton
                        className={
                            location.pathname.match('/admin/template') ? clsx(activeButtonClass) : clsx(passiveButtonClass)
                        }
                        to={'/admin/template'}>
                        {t('SiteHeader.テンプレート')}
                    </CLinkButton>
                    <CLinkButton
                        className={location.pathname.match('/admin/faq') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                        to={'/admin/faq'}>
                        {t('SiteHeader.よくある質問')}
                    </CLinkButton>
                    <CLinkButton
                        className={location.pathname.match('/admin/inquiry') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                        to={'/admin/inquiry'}>
                        {t('SiteHeader.問い合わせ')}
                    </CLinkButton>
                    <CLinkButton
                        className={
                            location.pathname.match('/admin/download') ? clsx(activeButtonClass) : clsx(passiveButtonClass)
                        }
                        to={'/admin/download'}>
                        {t('SiteHeader.資料DL')}
                    </CLinkButton>
                </div>
                <div className={clsx('flex', 'space-y-4', 'flex-col')}>
                    <div className={clsx('text-sm')}>
                        <div>{company?.name}</div>
                        <div>{user?.name}</div>
                    </div>
                    <div className={clsx('h-px', 'w-5/6', 'bg-gray-300')} />
                    {user?.roles.system && (
                        <CLinkButton className={clsx('c-button-secondary')} to={'/dashboard'}>
                            <img src="/src/assets/image/logo.svg" alt="logo" width="60" />
                            を開く
                        </CLinkButton>
                    )}
                    <CButton className={clsx('p-2', 'flex', 'space-x-2')} onClick={() => onClickSignOut()}>
                        <img src="/src/assets/image/icon/signout.svg" alt="signOut" />
                        <span>ログアウト</span>
                    </CButton>
                </div>
            </div>
        </>
    )
}

const GuestMenu = () => {
    return (
        <>
            <div className={clsx('flex', 'flex-col', 'justify-between', 'h-[80vh]')}>
                <div className={clsx('flex', 'space-y-4', 'flex-col')}>
                    <>
                        {location.pathname === '/' ? (
                            <AnchorLink href="#about" offset="64">
                                {t('SiteHeader.キマールとは')}
                            </AnchorLink>
                        ) : (
                            <Link to="/#about">{t('SiteHeader.キマールとは')}</Link>
                        )}
                    </>
                    <>
                        {location.pathname === '/' ? (
                            <AnchorLink href="#company" offset="64">
                                {t('SiteHeader.主な導入企業')}
                            </AnchorLink>
                        ) : (
                            <Link to="/#company">{t('SiteHeader.主な導入企業')}</Link>
                        )}
                    </>
                    <>
                        {location.pathname === '/' ? (
                            <AnchorLink href="#interview" offset="64">
                                {t('SiteHeader.お客様の声')}
                            </AnchorLink>
                        ) : (
                            <Link to="/#interview">{t('SiteHeader.お客様の声')}</Link>
                        )}
                    </>
                    <>
                        {location.pathname === '/' ? (
                            <AnchorLink href="#faq" offset="64">
                                {t('SiteHeader.よくある質問')}
                            </AnchorLink>
                        ) : (
                            <Link to="/#faq">{t('SiteHeader.よくある質問')}</Link>
                        )}
                    </>
                </div>
                <div className={clsx('flex', 'space-y-4', 'flex-col')}>
                    <CButton className={clsx('c-button-demo')}>
                        {location.pathname === '/' ? (
                            <AnchorLink href="#download" offset="64">
                                {t('SiteHeader.無料デモ申し込み')}
                            </AnchorLink>
                        ) : (
                            <Link to="/#download">{t('SiteHeader.無料デモ申し込み')}</Link>
                        )}
                    </CButton>
                    <CLinkButton className={clsx('c-button-secondary')} to={'/user/ielove'}>
                        {t('SiteHeader.ログイン')}
                    </CLinkButton>
                </div>
            </div>
        </>
    )
}

export type PcUserTopMenuProps = {
    activity?: ClientRealEstateActivityBooleanDto
    hasNewMarketEntity?: boolean
    company?: CompanyEntities
    user?: TeamMemberEntities
    onClickSignOut: () => void
}

const PcUserTopMenu = (props: PcUserTopMenuProps) => {
    const { activity, hasNewMarketEntity, company, user, onClickSignOut } = props
    const [openInfo, setOpenInfo] = useState(false)
    return (
        <div className={clsx('font-body', 'w-full', 'flex', 'justify-between', 'items-center', 'space-x-2')}>
            <div className={clsx('flex', 'justify-start', 'items-center', 'space-x-1')}>
                <CLinkButton
                    className={location.pathname.match('/dashboard') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                    to={'/dashboard'}>
                    {t('SiteHeader.ダッシュボード')}
                </CLinkButton>
                <CLinkButton
                    className={clsx(location.pathname.match('/market') ? activeButtonClass : passiveButtonClass, 'flex')}
                    to={'/market'}>
                    {t('SiteHeader.マーケット')}
                    {hasNewMarketEntity && <div className={clsx('w-2', 'h-2', 'rounded-full', 'bg-red-700')} />}
                </CLinkButton>
                <CLinkButton
                    className={clsx(location.pathname.match('/realestate') ? activeButtonClass : passiveButtonClass, 'flex')}
                    to={'/realestate'}>
                    {t('SiteHeader.物件')}
                    {(activity?.newChat || activity?.newConnection || activity?.newPublishRequest || activity?.newRecommend) && (
                        <div className={clsx('w-2', 'h-2', 'rounded-full', 'bg-red-700')} />
                    )}
                </CLinkButton>
                <CLinkButton
                    className={location.pathname.match('/map') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                    to={'/map'}>
                    {t('SiteHeader.地図')}
                </CLinkButton>
                <CLinkButton
                    className={location.pathname.match('/customer') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                    to={'/customer'}>
                    {t('SiteHeader.顧客')}
                </CLinkButton>
            </div>
            <div className={clsx('flex', 'justify-end', 'items-center', 'space-x-4')}>
                <div className={clsx('text-xs')}>
                    <div>{company?.name}</div>
                    <div>{user?.name}</div>
                </div>
                <div className={clsx('h-4', 'w-px', 'bg-gray-300')} />
                <div className={clsx('flex', 'items-center')}>
                    <CButton className={clsx('p-2')} onClick={() => setOpenInfo(!openInfo)}>
                        <img src="/src/assets/image/icon/question-svgrepo-com.svg" alt="faq" />
                        {openInfo && (
                            <div
                                className={clsx(
                                    'absolute',
                                    'right-14',
                                    'rounded',
                                    'divide-y',
                                    'bg-white',
                                    'text-left',
                                    'text-sm',
                                    'flex',
                                    'flex-col',
                                    'border',
                                )}>
                                <CLinkButton to={'/faq'} className={clsx('p-2')}>
                                    {t('SiteHeader.よくあるご質問')}
                                </CLinkButton>
                                <div className={clsx('p-2')}>
                                    <a
                                        href="https://teachme.jp/92337/f/kimar_manual?page=1"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {t('SiteHeader.使い方')}
                                    </a>
                                </div>
                                <CLinkButton to={'/inquiry'} className={clsx('p-2')}>
                                    {t('SiteHeader.お問い合わせ')}
                                </CLinkButton>
                            </div>
                        )}
                    </CButton>
                    <CLinkButton to={'/user/mypage'} className={clsx('p-2')}>
                        <img src="/src/assets/image/icon/mypage.svg" alt="faq" />
                    </CLinkButton>
                    {user?.roles.system && (
                        <CLinkButton className={clsx('c-button-secondary')} to={'/admin/dashboard'}>
                            <img src="/src/assets/image/admin_logo.svg" alt="logo" width="60" />
                            を開く
                        </CLinkButton>
                    )}
                    <CButton className={clsx('p-2')} onClick={() => onClickSignOut()}>
                        <img src="/src/assets/image/icon/signout.svg" alt="signOut" />
                    </CButton>
                </div>
            </div>
        </div>
    )
}

export type PcAdminTopMenuProps = {
    company?: CompanyEntities
    user?: TeamMemberEntities
    onClickSignOut: () => void
}

const PcAdminTopMenu = (props: PcAdminTopMenuProps) => {
    const { company, user, onClickSignOut } = props
    return (
        <div className={clsx('font-body', 'w-full', 'flex', 'justify-between', 'items-center', 'space-x-2')}>
            <div className={clsx('flex', 'justify-start', 'items-center', 'space-x-2')}>
                <CLinkButton
                    className={location.pathname.match('/admin/dashboard') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                    to={'/admin/dashboard'}>
                    {t('SiteHeader.KPI')}
                </CLinkButton>
                <CLinkButton
                    className={location.pathname.match('/admin/company') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                    to={'/admin/company'}>
                    {t('SiteHeader.会社')}
                </CLinkButton>
                <CLinkButton
                    className={location.pathname.match('/admin/template') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                    to={'/admin/template'}>
                    {t('SiteHeader.テンプレート')}
                </CLinkButton>
                <CLinkButton
                    className={location.pathname.match('/admin/faq') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                    to={'/admin/faq'}>
                    {t('SiteHeader.よくある質問')}
                </CLinkButton>
                <CLinkButton
                    className={location.pathname.match('/admin/inquiry') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                    to={'/admin/inquiry'}>
                    {t('SiteHeader.問い合わせ')}
                </CLinkButton>
                <CLinkButton
                    className={location.pathname.match('/admin/download') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                    to={'/admin/download'}>
                    {t('SiteHeader.資料DL')}
                </CLinkButton>
            </div>
            <div className={clsx('flex', 'justify-end', 'items-center', 'space-x-4')}>
                <div className={clsx('text-xs')}>
                    <div>{company?.name}</div>
                    <div>{user?.name}</div>
                </div>
                <div className={clsx('h-4', 'w-px', 'bg-gray-300')} />
                <div className={clsx('flex', 'items-center')}>
                    {user?.roles.system && (
                        <CLinkButton className={clsx('c-button-secondary')} to={'/dashboard'}>
                            <img src="/src/assets/image/logo.svg" alt="logo" width="60" />
                            を開く
                        </CLinkButton>
                    )}
                    <CButton className={clsx('p-2')} onClick={() => onClickSignOut()}>
                        <img src="/src/assets/image/icon/signout.svg" alt="signOut" />
                    </CButton>
                </div>
            </div>
        </div>
    )
}

const PcGuestTopMenu = () => {
    return (
        <div className={clsx('font-body', 'w-full', 'flex', 'justify-end', 'items-center', 'space-x-2')}>
            <>
                {location.pathname === '/' ? (
                    <AnchorLink href="#about" offset="64">
                        {t('SiteHeader.キマールとは')}
                    </AnchorLink>
                ) : (
                    <Link to="/#about">{t('SiteHeader.キマールとは')}</Link>
                )}
            </>
            <>
                {location.pathname === '/' ? (
                    <AnchorLink href="#company" offset="64">
                        {t('SiteHeader.主な導入企業')}
                    </AnchorLink>
                ) : (
                    <Link to="/#company">{t('SiteHeader.主な導入企業')}</Link>
                )}
            </>
            <>
                {location.pathname === '/' ? (
                    <AnchorLink href="#interview" offset="64">
                        {t('SiteHeader.お客様の声')}
                    </AnchorLink>
                ) : (
                    <Link to="/#interview">{t('SiteHeader.お客様の声')}</Link>
                )}
            </>
            <>
                {location.pathname === '/' ? (
                    <AnchorLink href="#faq" offset="64">
                        {t('SiteHeader.よくある質問')}
                    </AnchorLink>
                ) : (
                    <Link to="/#faq">{t('SiteHeader.よくある質問')}</Link>
                )}
            </>
            <CButton className={clsx('c-button-demo')}>
                {location.pathname === '/' ? (
                    <AnchorLink href="#download" offset="64">
                        {t('SiteHeader.無料デモ申し込み')}
                    </AnchorLink>
                ) : (
                    <Link to="/#download">{t('SiteHeader.無料デモ申し込み')}</Link>
                )}
            </CButton>
            <CLinkButton className={clsx('c-button-secondary')} to={'/user/ielove'}>
                {t('SiteHeader.ログイン')}
            </CLinkButton>
        </div>
    )
}

const UserTopMenu = () => {
    return (
        <div className={clsx('font-body', 'w-full', 'flex', 'justify-center', 'items-center', 'space-x-1')}>
            <CLinkButton
                className={location.pathname.match('/dashboard') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                to={'/dashboard'}>
                {t('SiteHeader.ダッシュボード')}
            </CLinkButton>
            <CLinkButton
                className={location.pathname.match('/market') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                to={'/market'}>
                {t('SiteHeader.マーケット')}
            </CLinkButton>
            <CLinkButton
                className={location.pathname.match('/realestate') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                to={'/realestate'}>
                {t('SiteHeader.物件')}
            </CLinkButton>
            <CLinkButton
                className={location.pathname.match('/map') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                to={'/map'}>
                {t('SiteHeader.地図')}
            </CLinkButton>
            <CLinkButton
                className={location.pathname.match('/customer') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                to={'/customer'}>
                {t('SiteHeader.顧客')}
            </CLinkButton>
        </div>
    )
}
const AdminTopMenu = () => {
    return (
        <div className={clsx('font-body', 'w-full', 'flex', 'justify-start', 'items-center', 'space-x-1')}>
            <CLinkButton
                className={location.pathname.match('/admin/dashboard') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                to={'/admin/dashboard'}>
                {t('SiteHeader.KPI')}
            </CLinkButton>
            <CLinkButton
                className={location.pathname.match('/admin/company') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                to={'/admin/company'}>
                {t('SiteHeader.会社')}
            </CLinkButton>
            <CLinkButton
                className={location.pathname.match('/admin/template') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                to={'/admin/template'}>
                {t('SiteHeader.テンプレート')}
            </CLinkButton>
            <CLinkButton
                className={location.pathname.match('/admin/faq') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                to={'/admin/faq'}>
                {t('SiteHeader.よくある質問')}
            </CLinkButton>
            <CLinkButton
                className={location.pathname.match('/admin/inquiry') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                to={'/admin/inquiry'}>
                {t('SiteHeader.問い合わせ')}
            </CLinkButton>
            <CLinkButton
                className={location.pathname.match('/admin/download') ? clsx(activeButtonClass) : clsx(passiveButtonClass)}
                to={'/admin/download'}>
                {t('SiteHeader.資料DL')}
            </CLinkButton>
        </div>
    )
}

const GuestTopMenu = () => {
    return (
        <div className={clsx('font-body', 'w-full', 'flex', 'justify-center', 'items-center', 'space-x-2')}>
            <CButton className={clsx('c-button-demo')}>
                {location.pathname === '/' ? (
                    <AnchorLink href="#download" offset="64">
                        {t('SiteHeader.無料デモ申し込み')}
                    </AnchorLink>
                ) : (
                    <Link to="/#download">{t('SiteHeader.無料デモ申し込み')}</Link>
                )}
            </CButton>
            <CLinkButton className={clsx('c-button-secondary')} to={'/user/ielove'}>
                {t('SiteHeader.ログイン')}
            </CLinkButton>
        </div>
    )
}

export default SiteHeader
