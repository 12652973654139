import clsx from 'clsx'
import { t } from 'i18next'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import { CHeader } from '~/components/common/cHeader/CHeader'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import { pageTitleTemplate } from '~/util/common/common'

const SignIn = () => {
    return (
        <div className={clsx('flex', 'flex-col', 'space-y-4', 'items-center')}>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('SignIn.サービス統合のお知らせ')}</title>
            </Helmet>
            <CHeader label={t('SignIn.サービス統合のお知らせ')} />
            <div
                className={clsx(
                    'w-full',
                    'max-w-md',
                    'bg-white',
                    'rounded-md',
                    'flex',
                    'flex-col',
                    'space-y-4',
                    'p-4',
                    'md:p-8',
                )}>
                <div className={clsx('p-6', 'bg-gray-50', 'rounded', 'shadow')}>
                    <p className={clsx('mb-2')}>平素より弊社サービスをご利用いただき、誠にありがとうございます。</p>
                    <div className={clsx('mb-6')}>
                        <p className={clsx('mb-2')}>
                            2025年3月3日（月）より、
                            <strong>キマールはいえらぶCLOUDへ統合</strong>
                            されました。
                            <br />
                            以下のボタンから、いえらぶCLOUDのログイン画面にアクセスしてください。
                            <br />
                            ご案内したID及びパスワードで、ログインいただけます。
                        </p>
                        <CLinkButton className={clsx('c-button-primary')} to={'https://cloud.ielove.jp/'}>
                            {t('SiteHeader.ログイン')}
                        </CLinkButton>
                    </div>
                    <div className={clsx('text-xs', 'text-gray-500', 'border-t', 'pt-2')}>
                        <p>
                            統合前のキマールにログインをご希望の場合は
                            <a href="/user/signin" className={clsx('text-blue-600', 'underline', 'hover:text-blue-800')}>
                                こちら
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SignIn
